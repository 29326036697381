<template>
  <div class="planning-filter">
    <title-bar
      :left="{ path: '/planning', text: 'Planning', icon: 'chevron-left' }"
      title="Filter"
    />

    <form @submit="onSubmit" action="" class="content-section planning-filter__content">
      <div class="form-group">
        <label for="employee">Medewerker</label>
        <select class="select-control" name="employee" id="employee">
          <option value="">Alle medewerkers</option>
          <option
            v-for="contact in contacts"
            :key="contact.id"
            :value="contact.id"
            :selected="selectedEmployee === parseInt(contact.id, 10)"
          >
            {{ contact.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="search">Zoekterm</label>
        <input
          class="form-control"
          type="text"
          name="search"
          id="search"
          :value="planningFilters ? planningFilters.search : ''"
        >
      </div>

      <div class="form-group">
        <label for="only_scheduled_projects" class="mb-0">
          <input
            type="checkbox"
            name="only_scheduled_projects"
            id="only_scheduled_projects"
            :checked="planningFilters ? planningFilters.onlyScheduledProjects : false"
            value="1"
          >
          Toon alleen geplande project
        </label>
      </div>

      <div class="form-group planning-filter__buttons">
        <button type="submit" class="btn btn--primary">
          Filter planning
        </button>

        <button type="submit" class="btn btn--default" @click="onResetClick">
          Reset
        </button>
      </div>
    </form>
  </div>
</template>

<script>
/* global gtag */

import { mapActions, mapGetters } from 'vuex';
import TitleBar from '../../components/TitleBar.vue';

export default {
  name: 'planning-filter',

  components: {
    TitleBar,
  },

  data() {
    return {
      selectedEmployee: null,
    };
  },

  computed: {
    ...mapGetters([
      'contacts',
      'planningFilters',
    ]),
  },

  mounted() {
    this.selectedEmployee = this.planningFilters && this.planningFilters.employeeId
      ? parseInt(this.planningFilters.employeeId, 10)
      : null;
  },

  methods: {
    ...mapActions([
      'applyPlanningFilter',
      'resetPlanningFilter',
    ]),

    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const employeeId = formData.get('employee') || null;
      const search = formData.get('search') || null;
      const onlyScheduledProjects = (formData.get('only_scheduled_projects') || '0') === '1';

      this.applyPlanningFilter({
        employeeId,
        search,
        onlyScheduledProjects,
      });

      if (typeof gtag !== 'undefined') {
        gtag('event', 'filter', {
          event_category: 'Planning',
          event_label: `${employeeId} - ${search}`,
        });
      }

      this.$router.push('/planning');
    },

    onResetClick(e) {
      e.preventDefault();
      e.stopPropagation();
      this.resetPlanningFilter();

      if (typeof gtag !== 'undefined') {
        gtag('event', 'reset', {
          event_category: 'Planning',
          event_label: '',
        });
      }
    },
  },
};
</script>

<style>
.planning-filter {
  height: 100%;
}

.planning-filter__buttons {
  display: flex;
}

.planning-filter__buttons button {
  flex: 1 1 auto;
}

.planning-filter__buttons button:last-child {
  flex-grow: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: .75rem;
}
</style>
