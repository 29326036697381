function normalizeSearch(str) {
  return str.toLowerCase().replace(' ', '');
}

export default (items, filters) => {
  if (!items) {
    return null;
  }

  if (typeof filters === 'undefined' || !filters) {
    return items;
  }

  return items.filter((item) => {
    const { crew } = item;

    if (filters.employeeId) {
      if (typeof crew === 'undefined' || !crew.length) {
        return false;
      }

      const isIds = typeof crew[0] === 'number';

      // API responses vary. Some return the crew as IDs ([1, 3, 42]), and others
      // return complete employee objects ([{ name: 'test', id: 123 }]).
      // We want to work with a constant list and transform it to always be an array of IDs
      const crewIds = isIds ? crew : crew.map((employee) => employee.id);

      if (crewIds.indexOf(parseInt(filters.employeeId, 10)) < 0) {
        return false;
      }
    }

    if (filters.onlyScheduledProjects) {
      return item.is_scheduled;
    }

    if (filters.search) {
      // Create a single string that can be easily searched
      const searchWords = normalizeSearch([
        item.title,
        item.id,
        item.project_number,
      ].join(' '));

      const searchWord = normalizeSearch(filters.search);

      if (searchWords.indexOf(searchWord) < 0) {
        return false;
      }
    }

    return true;
  });
};
